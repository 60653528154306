import crypto from "crypto";

const {
  VUE_APP_SECRET_KEY: secretKey,
  VUE_APP_SECRET_IV: secretIV,
  VUE_APP_ECNRYPTION_METHOD: encryptionMethod,
} = process.env;

if (!secretKey || !secretIV || !encryptionMethod) {
  console.error("secretKey, secretIV, and encryptionMethod are required");
  throw new Error("secretKey, secretIV, and encryptionMethod are required");
}

// Generate secret hash with crypto to use for encryption
const key = crypto
  .createHash("sha512")
  .update(secretKey)
  .digest("hex")
  .substring(0, 32);

const encryptionIV = crypto
  .createHash("sha512")
  .update(secretIV)
  .digest("hex")
  .substring(0, 16);

// Decrypt data
// eslint-disable-next-line no-unused-vars
export default (encryptedData) => {
  const buff = Buffer.from(encryptedData, "base64");
  const utfData = buff.toString("utf8");

  try {
    const decipher = crypto.createDecipheriv(
      encryptionMethod,
      key,
      encryptionIV
    );
    let decrypted =
      decipher.update(utfData, "hex", "utf8") + decipher.final("utf8");
    decrypted = decrypted.trim();

    decrypted = JSON.parse(decrypted);
    return decrypted;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};
