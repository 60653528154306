<template>
  <section class="mt-5 lg:mt-9">
    <h4 class="px-5 py-2.5 bg-ansLemon text-ansGreen text-center">
      Personal Information
    </h4>

    <!-- Section 1 -->
    <div v-if="$store.state.general.step == 1">
      <div class="px-4 lg:px-20 mt-2.5">
        <label for="phone">
          Phone number
          <span class="text-red-400">*</span>
        </label>
        <input
          type="number"
          class="input"
          id="phone"
          pattern="/\d*$/"
          onKeyPress="if(this.value.length==11) return false;"
          placeholder="080xxxxxxxx"
          v-model="form.phone"
        />
      </div>

      <div class="px-4 lg:px-20 mt-5">
        <label for="nin">
          National Identification Number (NIN)
          <span class="text-red-400">*</span>
        </label>
        <input
          type="number"
          class="input"
          id="nin"
          pattern="/\d*$/"
          onKeyPress="if(this.value.length==11) return false;"
          placeholder="Enter your NIN"
          v-model="form.nin"
        />
      </div>

      <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
        <button
          :disabled="!stepOneReady"
          @click="confirmNin"
          class="bg-ansGreen text-white w-full text-center py-3 rounded font-semibold text-xl"
        >
          Next
        </button>
      </div>
    </div>

    <!-- Section 2 -->
    <div v-if="$store.state.general.step == 2">
      <div class="px-4 lg:px-20 mt-5">
        <label for="confirmNin">
          Re-enter your National Identification Number (NIN)
          <span class="text-red-400">*</span>
        </label>
        <input
          type="number"
          class="input"
          id="confirmNin"
          pattern="/\d*$/"
          onKeyPress="if(this.value.length==11) return false;"
          placeholder="Re-enter your NIN"
          v-model="form.confirmNin"
        />
        <small class="text-xs text-red-700" v-if="ninNotEqual">
          Your NIN does not match
        </small>
      </div>

      <div class="flex justify-between gap-8 px-4 lg:px-20 mt-14 pb-3">
        <button
          @click="prev"
          class="bg-ansLemon text-ansGreen w-full text-center py-3 rounded font-semibold text-xl"
        >
          Back
        </button>

        <button
          :disabled="verifying || !stepTwoReady"
          @click="verifyNin"
          class="bg-ansGreen flex gap-2 justify-center text-white w-full text-center py-3 rounded font-semibold text-xl"
        >
          <span>Next</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </div>

    <!-- Section 3 -->
    <div v-if="$store.state.general.step == 3">
      <div class="px-4 lg:px-20 mt-5">
        <div
          class="bg-ansLemon border border-ansGreen py-5 text-center rounded font-bold"
        >
          <p class="text-xs">
            Generate your virtual NIN by dialing this USSD on your phone
          </p>
          <p class="mt-2">*346*3*{{ form.nin }}*348129#</p>
        </div>
      </div>

      <div class="px-4 lg:px-20 mt-5">
        <label for="vNin">
          Virtual NIN
          <span class="text-red-400">*</span>
        </label>
        <input
          type="text"
          class="input"
          id="vNin"
          :value="form.vNin | formatVnin"
          @input="updateVnin"
          placeholder="Enter the virtual NIN"
        />
      </div>

      <div class="flex justify-between gap-8 px-4 lg:px-20 mt-14 pb-3">
        <button
          @click="prev"
          class="bg-ansLemon text-ansGreen w-full text-center py-3 rounded font-semibold text-xl"
        >
          Back
        </button>

        <button
          :disabled="verifying || !stepThreeReady"
          @click="virtualNin"
          class="bg-ansGreen flex justify-center gap-2 text-white w-full text-center py-3 rounded font-semibold text-xl"
        >
          <span>Next</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </div>

    <!-- Section 4 -->
    <div v-if="$store.state.general.step == 4">
      <div class="px-4 lg:px-20 mt-5">
        <div
          class="bg-ansLemon border border-ansGreen py-3 text-center rounded font-bold"
        >
          <p class="text-xs">
            NIN Data verified successfully. See records below
          </p>
        </div>
      </div>

      <div class="flex justify-center items-center mt-6">
        <img
          :src="
            ninData.photograph
              ? ninData.photograph
              : require('@/assets/images/null_female.png')
          "
          alt="user picture"
          style="object-fit: contain"
          class="rounded-full h-18 w-18 lg:h-24 lg:w-24 border border-ansBlack"
        />
      </div>

      <div class="px-4 lg:px-20 mt-8">
        <label for="firstName">First name</label>
        <input
          type="text"
          readonly
          class="input"
          id="firstName"
          placeholder="Enter your first name"
          v-model="ninData.firstName"
        />
      </div>

      <div class="px-4 lg:px-20 mt-8">
        <label for="surname">Surname</label>
        <input
          type="text"
          readonly
          class="input"
          id="surname"
          placeholder="Enter your surname"
          v-model="ninData.surname"
        />
      </div>

      <div class="px-4 lg:px-20 mt-8">
        <label for="otherNames">Other names</label>
        <input
          type="text"
          readonly
          class="input"
          id="otherNames"
          placeholder="- include your other name here -"
          v-model="ninData.middleName"
        />
      </div>

      <div class="px-4 lg:px-20 mt-8">
        <label for="phone">Phone number</label>
        <input
          type="number"
          readonly
          class="input"
          id="phone"
          pattern="/\d*$/"
          onKeyPress="if(this.value.length==11) return false;"
          v-model="ninData.phone"
          placeholder="Enter your phone number"
        />
      </div>

      <div class="px-4 lg:px-20 mt-8">
        <label for="email">Email address</label>
        <input
          type="email"
          class="input"
          id="email"
          placeholder="Enter your email"
          v-model="form.email"
        />
      </div>

      <div class="px-4 lg:px-20 mt-8">
        <label for="townCode">
          Residential Town
          <span class="text-red-400">*</span>
        </label>
        <v-select
          id="townCode"
          label="townName"
          :reduce="(b) => b.townCode"
          :options="towns"
          v-model="form.townCode"
          placeholder="-- select town --"
        ></v-select>
      </div>

      <div class="px-4 lg:px-20 mt-8">
        <label for="areaCode">
          Residential Area
          <span class="text-red-400">*</span>
        </label>
        <v-select
          id="areaCode"
          label="areaName"
          :reduce="(b) => b.areaCode"
          :options="areas"
          v-model="form.areaCode"
          placeholder="-- select area --"
        ></v-select>
      </div>

      <div class="px-4 lg:px-20 mt-8">
        <label for="streetCode">
          Residential Street
          <span class="text-red-400">*</span>
        </label>
        <v-select
          id="streetCode"
          label="streetName"
          :reduce="(b) => b.streetCode"
          :options="streets"
          v-model="form.streetCode"
          placeholder="-- select street --"
        ></v-select>
      </div>

      <div class="flex justify-center px-4 lg:px-20 mt-8 pb-3">
        <button
          :disabled="verifying || !stepFourReady"
          @click="createPayer"
          class="bg-ansGreen flex justify-center gap-2 text-white w-full text-center py-3 rounded font-semibold text-xl"
        >
          <span>Next</span>
          <spinner v-if="verifying" />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {
  fetchTowns,
  fetchAreas,
  fetchStreets,
} from "../../services/sourceData";
export default {
  name: "PersonalInfo",

  data() {
    return {
      verifying: false,
      form: {
        phone: null,
        nin: null,
        confirmNin: null,
        vNin: null,
        email: null,
        townCode: null,
        areaCode: null,
        streetCode: null,
      },
      ninData: this.$store.getters["general/recp"],
      towns: [],
      areas: [],
      streets: [],
    };
  },

  async created() {
    this.towns = await fetchTowns();
  },

  computed: {
    ninNotEqual() {
      return (
        !!this.form.confirmNin &&
        this.form.confirmNin.length == 11 &&
        this.$store.getters["general/recp"].nin != this.form.confirmNin
      );
    },
    ninReady() {
      return !!this.form.nin && this.form.nin.length == 11;
    },
    stepOneReady() {
      return this.ninReady && !!this.form.phone;
    },
    stepTwoReady() {
      return (
        this.$store.getters["general/recp"].stepDone == 1 &&
        this.$store.getters["general/recp"].nin == this.form.confirmNin
      );
    },
    stepThreeReady() {
      return (
        this.$store.getters["general/recp"].stepDone == 2 && !!this.form.vNin
      );
    },
    stepFourReady() {
      return !!this.form.townCode && this.form.areaCode && this.form.streetCode;
    },
  },

  watch: {
    async "form.townCode"(nv) {
      if (!nv) {
        this.streets = [];
        this.areas = [];
        return;
      }
      this.areas = await fetchAreas(this.form.townCode);
    },

    async "form.areaCode"(nv) {
      if (!nv) {
        this.streets = [];
        return;
      }
      this.streets = await fetchStreets(this.form.areaCode);
    },
  },

  filters: {
    formatVnin(value) {
      return value
        ? value
            .match(/.{1,4}/g)
            .join(" ")
            .toUpperCase()
        : "";
    },
  },

  methods: {
    updateVnin(e) {
      this.form.vNin = e.target.value.replace(/ /g, "");
    },
    updateStore(step) {
      this.$store.commit("general/updateStep", step);
      // this.$router.push({
      //   name: "Track",
      //   query: { st: step },
      // });
    },
    prev() {
      const step = this.$store.state.general.step - 1;
      this.updateStore(step);
    },
    next() {
      const step = this.$store.state.general.step + 1;
      this.updateStore(step);
    },
    saveData(data) {
      this.$store.commit("general/updateReceiptData", data);
    },
    confirmNin() {
      this.saveData({ ...this.form, stepDone: 1 });
      this.next();
    },
    async verifyNin() {
      try {
        this.verifying = true;

        const res = await this.$http.post("/payer/verify-payer", this.form, {
          headers: { noauth: true },
        });
        this.verifying = false;

        if (!res) {
          return;
        }

        const { data } = res;

        // skip kyc for existing users
        if (data.code == "S200") {
          this.saveData({ ...data.data, stepDone: 2 });
          this.updateStore(5);
          this.$emit("done", data.data);
          return;
        }

        this.saveData({ stepDone: 2 });
        this.next();
      } catch (err) {
        console.log(err);
        this.verifying = false;
      }
    },

    async virtualNin() {
      try {
        this.verifying = true;

        const res = await this.$http.post(
          "/payer/verify-nin-profile",
          this.form,
          {
            headers: { noauth: true },
          }
        );
        this.verifying = false;

        if (!res) {
          return;
        }

        const { data } = res;

        this.saveData(data.data);
        this.ninData = this.$store.getters["general/recp"];
        this.next();
      } catch (err) {
        console.log(err);
        this.verifying = false;
      }
    },

    async createPayer() {
      try {
        if (!this.stepFourReady) {
          this.$swal({
            icon: "error",
            text: "Fill all required fields",
          });
          return;
        }
        this.verifying = true;

        const res = await this.$http.post(
          "payer/create-payer",
          { ...this.form, nin: this.$store.getters["general/recp"].nin },
          {
            headers: { noauth: true },
          }
        );
        this.verifying = false;

        if (!res) {
          return;
        }

        const { data } = res;
        this.saveData(data.data);

        this.updateStore(5);
        this.$emit("done", data.data);
      } catch (err) {
        console.log(err);
        this.verifying = false;
      }
    },
  },
};
</script>

<style scoped></style>
